import styled from "styled-components";
import { themeGet } from "@styled-system/theme-get";

import { Wrapper } from "../Grid";

const GlossaryWrapper = styled(Wrapper)`
  position: relative;
  background-color: ${themeGet("colors.darkGreen")};
  overflow: hidden;
`;

export { GlossaryWrapper };
