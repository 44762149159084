import styled from "styled-components";
import propTypes from "@styled-system/prop-types";
import themeGet from "@styled-system/theme-get";

const GlossaryDefinition = styled("dl")`
  list-style: none;
  margin: 0;
  padding: 0;
  margin-block-start: 0;
  margin-block-end: 0;
  width: 100%;
  border-bottom: 1px solid #ccd9db;
  dt {
    width: 100%;
    padding-right: 5px;
  }

  dt h2 {
    color: ${themeGet("colors.darkGreen")};
  }
  dt a:hover h2 {
    text-decoration: underline;
  }
  dt a:hover h2,
  dt.open h2 {
    color: ${themeGet("colors.cyberGreen")};
  }

  dt span {
    font-size: 36px;
    transition: transform 0.25s ease-in-out;
    color: ${themeGet("colors.lightTitaniumGrey")};
    cursor: pointer;
  }
  dt.open span,
  dt a:hover span {
    color: ${themeGet("colors.darkGreen")};
  }
  dt.open span {
    transform: rotate(45deg);
  }
  dd {
    margin-inline-start: 0;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s ease-in-out;
    color: ${themeGet("colors.textGrey")};
  }
  dd.open {
    max-height: 99em;
  }
`;

GlossaryDefinition.displayName = "GlossaryDefinition";

GlossaryDefinition.defaultProps = {
  as: "dl",
  display: "block",
  fontSize: 2,
  fontWeight: "regular",
  color: "textGrey",
};

GlossaryDefinition.propTypes = {
  ...propTypes.space,
  ...propTypes.color,
  ...propTypes.layout,
  ...propTypes.typography,
  ...propTypes.position,
};

export { GlossaryDefinition };
