import { parseContent } from "../utils/lang";
import React, { Fragment } from "react";
import Meta from "../components/Meta";
import { GlossaryWrapper } from "../style/Glossary";
import { Box, Container, Wrapper } from "../style/Grid";
import HeaderInner from "../components/HeaderInner";
import { Heading } from "../style/Heading";
import { Wysiwyg } from "../style/Wysiwyg";
import { Image } from "../style/Image";
import parse from "html-react-parser";
import GlossaryDefinition from "../components/GlossaryDefinition";

export default ({
  data,
  pageContext: { translations, links, lang, currentSlug },
  mobileMenuOpen,
  toggleMobileMenu,
}) => {
  const { frontmatter, html } = parseContent(data.markdownRemark, lang);

    return true ? null : (
    <Fragment>
      <Meta
        title={frontmatter.meta.title}
        description={frontmatter.meta.description}
      />
      <GlossaryWrapper p={0}>
        <Wrapper position="relative" zIndex={1000}>
          <HeaderInner
            mobileMenuOpen={mobileMenuOpen}
            toggleMobileMenu={toggleMobileMenu}
            translations={translations}
            links={links}
            lang={lang}
            slug={currentSlug}
          />
        </Wrapper>
        <Container
          p={[5, null, null, 100]}
          pb={[0, null, null, 100]}
          position="relative"
          zIndex={50}
        >
          <Heading as="h1" fontSize="h1" mb={[5, null, null, 16]} color="white">
            {frontmatter.title}
          </Heading>

          <Box maxWidth={800}>
            <Wysiwyg dangerouslySetInnerHTML={{ __html: html }} color="white" />
          </Box>
          <Image
            src={`/ico-vocabulary.svg`}
            position={["relative", null, "absolute"]}
            width={["100%", "auto"]}
            bottom={[0, null, "-40px"]}
            right={[0, null, "100px"]}
            zIndex={5}
            alt={frontmatter.title}
          />
        </Container>
      </GlossaryWrapper>
      <Wrapper>
        <Container
          flexWrap="wrap"
          flexDirection="row"
          justifyContent="space-between"
          pt={[1]}
          pb={[1, null, null, 80]}
          px={[3, null, null, 200]}
        >
          {data.glossary.nodes
            .map(item => parseContent(item, lang))
            .map(item => (
              <GlossaryDefinition
                key={item.frontmatter.slug}
                title={item.frontmatter.title}
                slug={item.frontmatter.slug}
                initiallyOpen={
                  data.currentGlossaryItem &&
                  item.id === data.currentGlossaryItem?.id
                }
              >
                {parse(item.html)}
              </GlossaryDefinition>
            ))}
        </Container>
      </Wrapper>
    </Fragment>
  );
};
