import { graphql } from "gatsby";
import GlossaryCommon from "./glossaryCommon";

export default GlossaryCommon;
export const pageQuery = graphql`
  query GLOSSARY_QUERY($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        slug
        slug_cz

        title
        title_cz

        meta {
          title
          description
        }
        meta_cz {
          title
          description
        }
      }
    }
    glossary: allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "glossaryItem" } } }
    ) {
      nodes {
        frontmatter {
          title
          title_cz
          slug
          slug_cz
        }
        html
      }
    }
  }
`;
